import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import "../BeInspiredSlider/assets/styles/_index.scss"
import "./OtherAreaGuides.scss"

const OtherAreaGuides = ({ currentAreaID }) => {
  const data = useStaticQuery(graphql`
    query GetOtherAreaguides {
      glstrapi {
        areaGuides {
          id
          slug
          name
          shortDescription
          tile_image {
            url
            url_sharp {
              id
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  height: 328
                  width: 264
                  layout: FIXED
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `)

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "20%",
        },
      },
    ],
  }
  // Slider settings

  const allAreaguides = data.glstrapi.areaGuides
  const filteredAreaGuides = allAreaguides.filter(
    area => area.id !== currentAreaID
  )
  // const arealist = data.glstrapi.globalModule.Be_Inspired.Area_Listings
  // const amenitieslist =
  //   data.glstrapi.globalModule.Be_Inspired.Amenities_Listings
  return (
    <div className="other-areaguides-wrap section-p">
      <div className="be-inspired-slider-wrapper">
        <Container>
          <Row>
            <Col>
              <h4>Be Inspired</h4>
              <div className="d-flex justify-content-between align-items-center be-inspired-header-wrapper">
                <h2>Other communities</h2>
                <Link
                  to={"/about/dubai-communities/"}
                  className="view-more d-md-block d-none"
                >
                  View More
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Slider className="be-inspired-slider" {...settings}>
                {filteredAreaGuides &&
                  filteredAreaGuides.length > 0 &&
                  filteredAreaGuides.map((area, index) => {
                    return (
                      <>
                        <div className="be-inspired-slide">
                          <div className="be-inspired-img-zoom">
                            <Link
                              to={`/about/dubai-communities/living-in-${area.slug}/`}
                            >
                              <GetGatsbyImage
                                image={area.tile_image}
                                fallbackalt={area.name}
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                          <div className="be-inspired-text-wrapper">
                            <Link
                              to={`/about/dubai-communities/living-in-${area.slug}/`}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <div className="link-text">{area.name}</div>
                              <i className="icon icon-inspired-slider-link-arrow"></i>
                            </Link>
                            <p className="inspired-text">
                              {area.shortDescription}
                            </p>
                          </div>
                        </div>
                      </>
                    )
                  })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default OtherAreaGuides
