import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { GetPageURL } from "../common/site/utils"
import "./FullWidthBanner.scss"

const FullWidthBanner = ({ module, isAreaGuide }) => {

  const data = useStaticQuery(graphql`
    query GetFullBanners {
      glstrapi {
        globalModule {
          full_width_banner_modules {
            banner_image {
              url
            }
            ctas {
              label
              id
              target
              theme
              custom_link
              menu_item {
                slug
                label
                parent {
                  label
                  slug
                }
              }
            }
            heading
            id
            select_module
            title
          }
        }
      }
    }
  `)

  const filteredModules =
    data.glstrapi.globalModule.full_width_banner_modules.filter(
      tmodule => module.Select_Module === tmodule.select_module
    )

  const selectModule = isAreaGuide ? module : filteredModules?.length > 0 && filteredModules[0]

  if (!selectModule && !module.Title) return null

  return (
    <div className="full-width-banner-wrapper">
      <img src={selectModule.banner_image.url} alt="full width banner" className="banner-img" />
      <div className="overlay"></div>
      <Container className="banner-container full-width-banner-container">
        <h4 className="title">{module.Title || selectModule.title}</h4>
        <h2 className="heading">
          {module.Description || selectModule.heading}
        </h2>
        <div className="cta-section">
          {selectModule.ctas?.length > 0 &&
            selectModule.ctas.map(cta => (
              <Link
                to={GetPageURL(cta.menu_item, cta.custom_link)}
                className="btn btn-transparent-blur"
              >
                {cta.label}
              </Link>
            ))}
        </div>
      </Container>
    </div>
  )
}

export default FullWidthBanner
