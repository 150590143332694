import gql from "graphql-tag"

// Team Categories
const TeamCategories = gql`
    query GetTeamsList {
        teamsCategories {
            id
            url
            category
        }
    }
`

// All Teams
const AllTeams = gql`
    query GetTeams {
        teams {
            id
            name
            designation
            slug
            teams_categories {
                id
                category
            }
            tile_image {
                url
            }
        }
    }
`
// News Categories
const NewsCategories = gql`
    query GeBlogsCat {
        blogsCategories {
            id
            url
            category
        }
    }
`

// All News
const AllNews = gql`
    query GetNews {
        blogs {
            id
            title
            slug
            tile_image {
              url
              alternativeText
            }
            blogs_categories {
              id
              url
              category
            }
            imagetransforms
          }
    }
`

/* ========== Property Detail For Booking ======== */

const GetPropertyBooking = gql`
query GetPropertyOffice($id: String!) {
  properties (where:{id:$id}) {
    id
    slug
    address
    display_address
    bedroom
    bathroom
    images
  }
}`


/* ================== Featured Residential Properties ================= */ 


const AllProperties = gql`
query GetAllProperties($search_type: String!, $department: String!) {
    properties(limit:6, where:{search_type:$search_type, department:$department})  {
        price
        max_price
        id
        description
        building
        display_address
        area
        slug
        long_description
        extra
        bedroom
        floorarea_min
        bathroom
        reception
        virtual_tour
        images
        search_type
        status
        imagetransforms
    }
}`


export const Queries = {
    TeamCategories,
    AllTeams,
    NewsCategories,
    AllNews,
    GetPropertyBooking,
    AllProperties
  }