import React from "react"
import { Accordion, Container } from "react-bootstrap"
import "./LandingAboutModule.scss"
import parse from "html-react-parser"
import { getWhatsAppURL } from "../../common/misc"
import { Link } from "gatsby"
const LandingAboutModule = ({ aboutContent, team }) => {
  return (
    <Container className="landing-about-wrap section-m">
      <div className="landing-about-container">
        <div className="about-content-section">
          <p className="heading">{aboutContent.heading}</p>
          {aboutContent.description && (
            <div className="description">{parse(aboutContent.description)}</div>
          )}
          {aboutContent?.accordianContent && (
            <div className="accordian-section">
              <Accordion defaultActiveKey="0">
                {aboutContent?.accordianContent.map((content, i) => (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header><h4>{content.title}</h4></Accordion.Header>
                    <Accordion.Body>
                      {parse(content.description)}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          )}
        </div>
        {team && (
          <div className="contact-section">
            <p className="heading d-none d-lg-block">Your Local Agent</p>

            <img src={team.tile_image?.url} alt="team-img" className="team-img" />
            <div className="team-content">
              <p className="heading d-block d-lg-none">Your Local Agent</p>
              <div className="name-section">
                <p className="name">{team.name}</p>
                <i className="icon page-arrow-next"></i>
              </div>
              <p className="designation">{team.designation}</p>
              <div className="cta-section">
                <div className="icon-section">
                  <i className="icon icon-phone"></i>
                  <a href={"tel:" + team.mobile_no} className="icon-label">
                    {team.mobile_no}
                  </a>
                </div>
                <div className="icon-section">
                  <i className="icon icon-whatsapp"></i>
                  <a
                    href={getWhatsAppURL(team.mobile_no)}
                    className="icon-label"
                    target={"_blank"}
                  >
                    click to whatsapp
                  </a>
                </div>
                <div className="icon-section">
                  <i className="icon icon-nego-mail"></i>
                  {/* <a href={"#"} className="icon-label">
                    eamil {team.name.split(" ")[0]}
                  </a> */}
                  <Link
                  to="/contact-omhome/"
                  className="icon-label"
                  state={{
                    teamName: team.name,
                    teamEmail: team.email,
                  }}
                  
                >email {team.name.split(" ")[0]}</Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default LandingAboutModule
