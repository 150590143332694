import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import Select from "react-select"
import Slider from "react-slick"
import { useMedia } from "react-use"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { AllProperties } from "../../queries/common_use_query"
import PropertyCard from "../PropertyCard/PropertyCard"
import PropertyCardOffplan from "../PropertyCard/PropertyCardOffplan"
import "./assets/styles/_index.scss"
import $ from "jquery"

const OPTIONS = [
    { value: "property-for-sale", label: "Property for sale" },
    { value: "property-to-rent", label: "Property for rent" },
    { value: "off-plan", label: "Off plan" },
  ]

const FeaturedPropertiesSlider = () => {
  const isTablet = useMedia("(max-width: 992px)", false)

  const data = useStaticQuery(graphql`
    query GetFeaturedOffplan {
      glstrapi {
        properties(
          where: {
            search_type: "sales"
            department: "building"
            publish: "true"
          }
          limit: 6
        ) {
          ...offplanCardDetails
        }
      }
    }
  `)

  const properties = data.glstrapi.properties

  const {
    loading: sale_loading,
    error: sale_error,
    data: sale_data,
  } = AllProperties("sales", "residential")
  const {
    loading: let_loading,
    error: let_error,
    data: let_data,
  } = AllProperties("lettings", "residential")

  const saleProperty = sale_data?.properties
  const letProperty = let_data?.properties

  const [selectedValue, setSelectedValue] = useState(OPTIONS[0])

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 400,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  // Dropdown react select styles
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#161413" : null,
        color: isFocused ? "#ffffff" : "#161413",
      }
    },
    control: styles => ({
      ...styles,
      backgroundColor: null,
      border: 0,
      paddingLeft: 0,
      outline: 0,
      boxShadow: "none",
      color: "#161413",
      fontSize: "1rem",
    }),
    valueContainer: styles => ({
      ...styles,
      fontSize: "1rem",
      paddingLeft: 0,
      lineHeight: "30px",
      cursor: "pointer",
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: "#161413",
    }),
    indicatorsContainer: styles => ({
      ...styles,
      color: "#161413",
      cursor: "pointer",
    }),
    indicatorSeparator: () => null,
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: "#161413",
        marginLeft: 0,
      }
    },
  }
  // Dropdown react select styles

  const handleSelect = (e) => {
    let tempOption

    if(typeof e === "string") {
        tempOption = OPTIONS.find(option => option.value === e)
    } else {
        tempOption = e
    }
    setSelectedValue(tempOption)
  }

  if(!(properties && saleProperty)) return null
  return (
    <section className="featured-properties-slider-wrapper section-p">
      <Container>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center featured-properties-header-wrapper">
              <h2>Featured Properties</h2>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            {/* commented because we are only showing offplan */}
            <Select
              options={OPTIONS}
              isSearchable={false}
              placeholder="Property for sale"
              className="featured-properties-dropdown"
              classNamePrefix="featured-properties-dropdown"
              styles={customStyles}
              components={{
                DropdownIndicator: () => (
                  <i className="icon icon-select-dropdown"></i>
                ),
                IndicatorSeparator: () => null,
              }}
              onChange={handleSelect}
              value={selectedValue}
              
            />

            <Tabs
              defaultActiveKey={OPTIONS[0].value}
              activeKey={selectedValue.value}
              className="featured-properties-tab"
              onClick={handleSelect}
            >
              <Tab
                eventKey={OPTIONS[0].value}
                title="Property for sale"
                className="featured-properties-tab-pane property-for-sale"
              >
                <Link to="/property/for-sale/" className="view-more">
                  View More
                </Link>
                <Slider
                  className="featured-properties-slider property-list-wrap"
                  {...settings}
                >
                  {saleProperty?.map(property => {
                    let processedImages = JSON.stringify({})
                    if (property?.imagetransforms?.images_Transforms) {
                      processedImages =
                        property.imagetransforms.images_Transforms
                    }

                    return (
                      <PropertyCard
                        price={"AED " + property.price.toLocaleString()}
                        propertyid={property.objectID}
                        location={property.display_address}
                        description={property.description}
                        bedroomsCount={property.bedroom}
                        building={property.building}
                        bathroomsCount={property.bathroom}
                        receptionsCount={property.reception}
                        propertyVideo={property.virtual_tour}
                        propertyImages={property.images}
                        propertyDetailsLink={`/property-for-sale/${property.slug}-${property.id}/`}
                        propertySearchType={property.search_type}
                        status={property.status}
                        floorarea_min={property.floorarea_min}
                        imagetransforms={property.imagetransforms}
                        // userObjects={userObjects}
                        card_type="similar_property"
                      />
                    )
                  })}
                </Slider>
              </Tab>
              <Tab
                eventKey={OPTIONS[1].value}
                title="Property for rent"
                className="featured-properties-tab-pane property-to-rent"
              >
                <Link to="/property/for-rent/" className="view-more">
                  View More
                </Link>
                <Slider
                  className="featured-properties-slider property-list-wrap"
                  {...settings}
                >
                  {saleProperty?.map(property => {
                    let processedImages = JSON.stringify({})
                    if (property?.imagetransforms?.images_Transforms) {
                      processedImages =
                        property.imagetransforms.images_Transforms
                    }

                    return (
                      <PropertyCard
                        price={"AED " + property.price.toLocaleString()}
                        propertyid={property.objectID}
                        location={property.display_address}
                        description={property.description}
                        bedroomsCount={property.bedroom}
                        building={property.building}
                        bathroomsCount={property.bathroom}
                        receptionsCount={property.reception}
                        propertyVideo={property.virtual_tour}
                        propertyImages={property.images}
                        propertyDetailsLink={`/property-for-sale/${property.slug}-${property.id}/`}
                        propertySearchType={property.search_type}
                        status={property.status}
                        floorarea_min={property.floorarea_min}
                        // userObjects={userObjects}
                        card_type="similar_property"
                      />
                    )
                  })}
                </Slider>
              </Tab>
              <Tab
                eventKey={OPTIONS[2].value}
                title="Off-plan"
                className="featured-properties-tab-pane off-plan"
              >
                <Link
                  to="/off-plan-properties/for-sale/in-dubai/"
                  className="view-more"
                >
                  View More
                </Link>
                <Slider
                  className="featured-properties-slider property-list-wrap"
                  {...settings}
                >
                  {properties.map(property => {
                    let processedImages = JSON.stringify({})
                    if (property?.imagetransforms?.images_Transforms) {
                      processedImages =
                        property.imagetransforms.images_Transforms
                    }

                    return (
                      <PropertyCardOffplan
                        price={"AED " + property.price.toLocaleString()}
                        maxPrice={
                          "AED " + property?.max_price?.toLocaleString()
                        }
                        propertyid={property.id}
                        location={property.description}
                        description={property.area}
                        propertyApartmentDesc={
                          property.extra?.apartment_description
                        }
                        propertyDeveloper={property.extra?.developer_name}
                        propertyCompletionDate={property.extra?.completionDate}
                        bedroomsCount={property.bedroom}
                        building={property.building}
                        bathroomsCount={property.bathroom}
                        receptionsCount={property.reception}
                        propertyVideo={property.virtual_tour}
                        propertyImages={property.images}
                        propertyDetailsLink={`/off-plan-property-for-sale/${property.slug}-${property.id}/`}
                        propertySearchType={property.search_type}
                        status={property.status}
                        sqft={property.size}
                        processedImages={processedImages}
                        card_type="similar_property"
                      />
                    )
                  })}
                </Slider>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export const offplanCardDetails = graphql`
  fragment offplanCardDetails on GLSTRAPI_Property {
    price
    max_price
    id
    description
    area
    slug
    long_description
    extra
    bedroom
    reception
    virtual_tour
    images
    search_type
    status
    imagetransforms
  }
`

export default FeaturedPropertiesSlider
