import { useQuery } from "@apollo/client"
import { Queries } from "./common_queries"

export const TeamCategories = () => {
    return useQuery(Queries.TeamCategories)
}

export const AllTeams = () => {
    return useQuery(Queries.AllTeams)
}

export const NewsCategories = () => {
    return useQuery(Queries.NewsCategories)
}

export const AllNews = () => {
    return useQuery(Queries.AllNews)
}
export const GetPropertyBooking = (id) => {
    return useQuery(Queries.GetPropertyBooking,{
        variables: {id: id}
    });
}

export const AllProperties = (search_type, department) => {
    return useQuery(Queries.AllProperties, {
        variables: {search_type: search_type, department: department}
    })
}
