import React from "react"
import "./BannerLandingSearch.scss"
import "../HomeBannerSearch/assets/styles/_index.scss"
import {
  GetPageURL,
  PageBreadcrumbs,
  scrollToElement,
} from "../common/site/utils"
import Breadcrumbs from "../BreadCrumbs/BreadCrumbs"
import DetailsBreadCrumbs from "../BreadCrumbs/DetailsBreadCrumbs"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { Container } from "react-bootstrap"
import BannerSearch from "../HomeBannerSearch/BannerSearch"
import iconTree from "../../images/icons/icon-tree.svg"
import iconTeams from "../../images/icons/icon-teams.svg"
import iconHome from "../../images/icons/icon-home.svg"
import iconAnchor from "../../images/icons/icon-anchor.svg"
import iconShop from "../../images/icons/icon-shop.svg"
import SearchOverlay from "../SearchOverlay/SearchFilter"
import { useMedia } from "react-use"
import { Link } from "gatsby"

const BannerLandingSearch = ({
  slug,
  id,
  imagetransforms,
  title,
  bgImg,
  areaguideBanner,
  stats,
}) => {
  let processedImages = JSON.stringify({})
  if (imagetransforms?.banner_image_Transforms) {
    processedImages = imagetransforms.banner_image_Transforms
  }

  const isMobile = useMedia("(max-width: 767px)", false)

  return (
    <div className="landing-banner-wrap">
      <div className="img-wrap">
        <GetGGFXImage
          imagename={
            areaguideBanner
              ? "area-guides.banner_image.bannerimg"
              : "pages.banner_image.bannerimg"
          }
          imagesource={bgImg}
          fallbackalt={title}
          imagetransformresult={processedImages}
          id={id}
        />
        <div className="overlay-bg"></div>
      </div>
      <div className="landing-banner-container-wrap">
        {areaguideBanner ? (
          <DetailsBreadCrumbs
            breadcrumbs={PageBreadcrumbs("dubai-communities")}
            title={title}
          />
        ) : (
          <Breadcrumbs breadcrumbs={PageBreadcrumbs(slug)} />
        )}
        <Container className="landing-banner-container">
          <h1 className="title">{title}</h1>
          <BannerSearch ptypetag="for-sale" homeBanner sales isLandingBanner/>

          {stats && (
            <div className="icons-section-wrap">
              {stats.map(stat => {
                let statUrl;
                if (stat.description === 'Properties for Sale') {
                  statUrl = '/property/for-sale/'
                } else if (stat.description === 'Off Plan Developments') {
                  statUrl = '/off-plan-properties/for-sale/in-dubai/'
                } 
              return (
                <div className="icon-section-container">
                  <i alt="icon" className={`icon img-icon icon-${stat.Icon}`} />
                  <div className="description-section">
                    <p className="label">{stat.Title}</p>
                    <Link to={statUrl}><p
                      className={`description ${
                        areaguideBanner ? "border-0" : ""
                      }`}
                    >
                      {stat.description}
                    </p></Link>
                  </div>
                </div>
                    )})}
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default BannerLandingSearch
