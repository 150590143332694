import React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
const Breadcrumbs = ({ breadcrumbs }) => {
    return(
        
    <Container fluid className="breadcrumb-container">
        <Row>
            <Col>
        <Breadcrumb className='d-block'>
            <Link to="/" variant="none" className="breadcrumb-item">Home</Link>
            {breadcrumbs?.map((breadcrumb, i, arr) => {
                if (arr.length - 1 === i) { // is the last item in the array
                    return (
                        <Breadcrumb.Item key={i} active>{breadcrumb.label}</Breadcrumb.Item>
                    )
                } else {
                    return(
                        <Link
                            key={i}
                            to={breadcrumb.url + "/"}
                            variant="none"
                            className="breadcrumb-item"
                        
                            
                        >
                            {breadcrumb.label}
                        </Link>
                    )
                }
            })}
        </Breadcrumb>
        </Col>
        </Row>
        </Container>
    )
}

export default Breadcrumbs
