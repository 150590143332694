import React, { useState } from "react"
import { Link } from "gatsby"
import "./PropertyCard.scss"
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { Col } from "react-bootstrap"
import { getWhatsAppURL } from "../../common/misc";
import NoImage from "../../images/no-image.png"
import { SaveItem } from "@myaccount/website-service"
import Slider from "react-slick"
import _ from "lodash"
import { phoneNumber } from "../common/site/utils"

const PropertyCard = props => {
  const propertyitem = props
  const userObjects = props.userObjects

  const [icon, setIcon] = useState(false)
  const showIcon = () => {
    setIcon(!icon)
  }

  //
  const shareurl = typeof window !== "undefined" ? window.location.href : ""
  const trackerEvent = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: event,
      formType: event + " - " + shareurl,
      formId: event,
      formName: event,
      formLabel: event,
    })
  }

  //

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
  }
  // Slider settings
  let lg = '';
  let md = '';

  if (props.card_type !== "similar_property") {
    lg = 6
    md = 6
  }

  return (
    <React.Fragment>
      <Col id={propertyitem.propertyid} lg={lg} md={md}>
        <div className="property-card">
          <div className="properties-wrapper">
          {_.isEmpty(propertyitem?.propertyImages) ? (
              <Link to={props.propertyDetailsLink}>
                <picture>
                  <img
                    src={NoImage}
                    alt={propertyitem.location + " - Om Home"}
                  />
                </picture>
              </Link>
            ) : (
              <Slider {...settings} className="property-card-slider-wrap">
                {propertyitem?.propertyImages.map(image => {
                  let processedImages = propertyitem.imagetransforms
                  processedImages ||= JSON.stringify({})

                  return (
                    <Link to={props.propertyDetailsLink}>
                      <picture>
                        {props.card_type == "similar_property" ? (
                          // <GetGGFXImage
                          //   imagename={"property.images.featuredProperties"}
                          //   imagesources={image}
                          //   fallbackalt={propertyitem.location}
                          //   imagetransformresult={processedImages}
                          //   id={props.propertyid}
                          // />
                          <img
                            src={propertyitem?.propertyImages[0]?.srcUrl || NoImage}
                            alt={propertyitem.location}
                          />
                        ) : (
                          <ShowProcessedImage
                            images={image}
                            attr={{
                              className: "mb-0 img-fluid",
                              alt: propertyitem.location + " - Om Home",
                            }}
                            transforms={imageConfig.property.searchResults.sizes}
                          />
                        )}
                      </picture>
                    </Link>
                  )
                })}
              </Slider>
            )}
          </div>
          <div className="about-properties">
            <div className="properties-info">
              <div className="properties-heading">
                <Link to={props.propertyDetailsLink}>
                  <h2>{propertyitem.price}</h2>
                </Link>

                <SaveItem
                  type="property"
                  pid={props.propertyid}
                  userObjects={userObjects}
                >
                  <i
                    className="icon icon-heart"
                    onClick={() => trackerEvent("Save Property")}
                  ></i>
                </SaveItem>

                {/* <i onClick={showIcon}  className={icon ? "icon-fill" : "icon-like"}></i> */}
              </div>

              <p>{propertyitem.location || "Al Andalus, Jumeirah Golf Estates"}</p>
            </div>

            <div className="properties-icons">
              <span className="text">
                <span className="sm-text">
                  {propertyitem.building || "Apartment"} <span>|</span>
                </span>
              </span>
              <span className="text">
                <i className="icon-bedroom"></i>
                <span className="sm-text">
                  {propertyitem.bedroomsCount} Bed<span>|</span>
                </span>
              </span>
              <span className="text">
                <i className="icon-bath"></i>
                <span className="sm-text">
                  {propertyitem.bathroomsCount || "3"} Bath<span>|</span>
                </span>
              </span>
              {propertyitem.floorarea_min && (
                <>
                    <span className="text">
                      <i className="icon-size"></i>
                      <span className="sm-text">
                        {propertyitem.floorarea_min.toLocaleString() || "1200"} sqft
                    </span>
                  </span>
                </>
              )}
            </div>

            <div className="property-consultant-details">
              <img src={NoImage} alt="property no img" className="consultant-image" />
              <p className="name">Alan</p>
              <a href={getWhatsAppURL(phoneNumber)} target="_blank">
                <i className="icon icon-whatsapp"></i>
              </a>
              <a href={`tel:${phoneNumber}`} className="phone-section">
                <i className="icon icon-phone"></i>
                <span>{phoneNumber} </span>
              </a>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default PropertyCard
